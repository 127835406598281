<template>
	<div class="card card-shadow">
		<div class="card-body">
			<h4>27th Oct 2021</h4>
			<hr>
			<div class="row" v-if="$hasTenantModule(1)">
				<div class="col-12">
					<h5>Desk Updates</h5>
					<p>Main updates today are the new implementation of the ticket active timer button shown in the top right control menu. Looks like this: <em class="ni ni-ticket"></em>.</p>
					<p>This allows all Desk users to see all of their active timers on tickets and stop them if they are leaving them running by accident.</p>
					<p>We also have revisited some of the ticket allocation settings and performed a lot of testing to get them accurate and informational. We have also revisited one of the reports "Account Issues" and rebuilt it to be more accurate and allow for a more in depth look into how your customers are using their allocations.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  created() {
          this.getView()
    },
    methods:{
        getView (){ this.$http.get('/ViewAccess/systemupdates')
        .then(() => { 
        })
        .catch(() => { 
        })},
    }
}
</script>

<style>

</style>